import React, { Suspense, useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./scss/rtl-toastify.scss";
import { CSpinner } from "@coreui/react";
import { LoadingProvider, useLoading } from "./contexts/LoadingContext";
import emailjs from "@emailjs/browser";

import "./scss/style.scss";

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// Pages
const ViewFolder = React.lazy(() => import("./views/pages/viewFolder"));
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

function App() {
  useEffect(() => {
    // Initialize EmailJS with your user ID
    emailjs.init("nm8c-WmAgmaZqqOgI");
  }, []);

  return (
    <LoadingProvider>
      <AppContent />
      <GlobalSpinner />
    </LoadingProvider>
  );
}

function AppContent() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const isViewFolderRoute = location.pathname.startsWith("/view-folder");
    const isLoginRoute = location.pathname === "/login";
    if (
      !isViewFolderRoute &&
      !isLoginRoute &&
      !localStorage.getItem("gluska-token")
    ) {
      navigate("/login");
    }
  }, [location, navigate]);

  return (
    <>
      <ToastContainer
        position="top-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={true}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Suspense fallback={loading}>
        <Routes>
          <Route exact path="/login" name="Login Page" element={<Login />} />
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
          <Route path="/view-folder/:id" element={<ViewFolder />} />
          <Route path="*" name="/home" element={<DefaultLayout />} />
        </Routes>
      </Suspense>
    </>
  );
}

function GlobalSpinner() {
  const { isLoading } = useLoading();

  if (!isLoading) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        zIndex: 9999,
      }}
    >
      <CSpinner />
    </div>
  );
}

export default App;
